<template>
  <div class="custom-action-row">
    <p class="current-selection">{{ days }}</p>
    <n-button
      type="primary"
      size="large"
      class="mt-2 mb-4"
      @click="$emit('selectDate')"
      :disabled="!isValidRangeOfDates"
      >{{ t("commons.actions.select") }}</n-button
    >
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { eachDayOfInterval, isSameDay } from "date-fns";
import { NButton } from "naive-ui";
import { quantityDaysInRange } from "@/shared/utils";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { NButton },
  emits: ["selectDate", "closePicker"],
  props: {
    internalModelValue: { type: [Date, Array], default: null },
    range: { type: Boolean, default: false },
    previewFormat: {
      type: [String, Function],
      default: () => "",
    },
    monthPicker: { type: Boolean, default: false },
    timePicker: { type: Boolean, default: false },
    customProps: { type: Object },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const days = computed(() => {
      if (
        props.internalModelValue &&
        props.internalModelValue[0] &&
        props.internalModelValue[1] &&
        validRangeOfDatesRef.value
      ) {
        return `${quantityDaysInRange(
          props.internalModelValue[0],
          props.internalModelValue[1]
        )} Days`;
      }
      return "";
    });

    const validRangeOfDatesRef = computed(() => {
      if (
        props.internalModelValue &&
        props.internalModelValue[0] &&
        props.internalModelValue[1]
      ) {
        const daysInRange = eachDayOfInterval({
          start: props.internalModelValue[0],
          end: props.internalModelValue[1],
        });
        try {
          props.customProps.history.forEach((day) => {
            daysInRange.forEach((newDay) => {
              if (isSameDay(day.date, newDay)) {
                throw new Error("Bad dates");
              }
            });
          });
        } catch (e) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    });
    return {
      t,
      days,
      isValidRangeOfDates: validRangeOfDatesRef,
    };
  },
});
</script>

<style lang="scss">
.custom-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.current-selection {
  margin: 10px 0 0 0;
}
</style>
